<style scoped lang="scss">
  .stream-stats-list {
    position: relative;

    .stream-stats-title {
      font-size: 24px;
      line-height: 30px;
    }

    .stream-stats-helper {
      margin-top: 16px;
      max-width: 700px;
    }

    .widgets-list {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-auto-rows: minmax(130px, 1fr);
      grid-gap: 20px;

      @include media(max, md) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include media(max, sm) {
        grid-template-columns: minmax(0, 1fr);
      }

      .widget-list-item {
        background: #262626;
        border-radius: 8px;
        padding: 16px;
        display: flex;
        flex-direction: column;

        .widget-title {
          font-size: 16px;
          line-height: 20px;
        }

        .widget-description {
          margin-top: 10px;
          opacity: .7;
        }

        .widget-link {
          margin-top: 15px;
        }

        .widget-controls {
          margin-top: auto;
          padding-top: 15px;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .widget-button + .widget-button {
            margin-left: 15px;
          }

          .widget-button {
            font-size: 18px;
            color: white;
            opacity: .5;
            cursor: pointer;

            &:hover {
              opacity: 1;
            }
          }
        }
      }

      .new-widget-button {
        background: transparent;
        border: 3px dashed $additional-3;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: $bright;
        text-decoration: none;

        &:hover {
          border-color: $black-four;
        }

        .button-icon {
          font-size: 14px;
        }

        .button-text {
          margin-left: 12px;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .widget-list-loader {
      @include position-all(absolute, 0);
      z-index: 10;
      background-color: rgba($black, .5);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>

<template>
  <div class="stream-stats-list">
    <transition name="fade">
      <div v-if="loadingState.list" class="widget-list-loader">
        <loader size="lg"/>
      </div>
    </transition>
    <div class="stream-stats-title">
      {{ $t('streamStats.title') }}
    </div>
    <info-box class="stream-stats-helper">
      {{ $t('streamStats.pageHelper') }}
      <br><br>{{
        $t('streamStats.pageHelperBeta')
      }}&nbsp;<a href="/help" target="_blank">{{ $t('dashboard.misc.support') }}</a>
    </info-box>
    <div class="widgets-list">
      <div
        v-for="widget in widgetsList"
        :key="widget.id"
        class="widget-list-item">
        <div class="widget-title">{{ widget.title }}</div>
        <div class="widget-description">{{ widget.helper }}</div>
        <widget-link :link="widget.link"/>
        <div class="widget-controls">
          <div
            class="widget-button"
            v-tooltip="$tooltip($t('streamStats.copyWidget'))"
            @click="copyWidget(widget.id)"
          >
            <icon name="copy"/>
          </div>
          <div class="widget-button" @click="askToDeleteWidget(widget.id)">
            <icon name="trash"/>
          </div>
          <router-link class="widget-button" :to="widget.to">
            <icon name="gear"/>
          </router-link>
        </div>
      </div>
      <router-link class="new-widget-button" :to="createLink">
        <icon name="plus" class="button-icon"/>
        <div class="button-text">{{ $t('streamStats.addWidget') }}</div>
      </router-link>
    </div>

    <confirm-modal
      v-model="deletingConfirmModalOpened"
      :title="widgetDeletingModalProps.title"
      :message="widgetDeletingModalProps.message"
      @confirm="removeWidget(widgetIdToRemove)"/>
  </div>
</template>

<script>

import dataTypeSettings from '@src/config/streamStats/dataTypeSettings'
import WidgetLink from '@components/Dashboard/WidgetLink'

export default {
  name: 'StreamStatsList',
  components: { WidgetLink },
  data() {
    return {
      loadingState: {
        list: true,
      },

      widgetIdToRemove: null,
    }
  },
  computed: {
    ...mapGetters('streamStats/widget', ['getWidgetsList', 'getWidget']),
    ...mapGetters('profile', ['getCurrentProfile']),

    ...mapState('dashboard', ['newDashboard']),

    createLink() {
      return this.newDashboard ? {
        name: 'dashboard-widget-stream-stats', params: { id: 'create' },
      } : {
        name: 'stream-stats-widget', params: { id: 'create' },
      }
    },

    widgetsList() {
      return this.getWidgetsList.map(widget => {
        const { dataType, dataPeriod } = widget.json.settings

        const withPeriod = dataTypeSettings[dataType].allowPeriod
        const titlePath = withPeriod ? `titlePeriod.${dataPeriod}` : 'title'
        const customPeriod = '22.04.2022 - 04.05.2022'

        const token = _.get(this.getCurrentProfile, 'token')

        return {
          ...widget,
          helper: this.$t(`streamStats.dataType.${dataType}.${titlePath}`, { customPeriod }),
          link: `${window.location.origin}/widget/stream-stats/${widget.id}?token=${token}`,
          to: this.newDashboard ? {
            name: 'dashboard-widget-stream-stats', params: { id: widget.id },
          } : {
            name: 'stream-stats-widget', params: { id: widget.id },
          },
        }
      })
    },

    deletingConfirmModalOpened: {
      get() {
        return this.widgetIdToRemove !== null
      },
      set(value) {
        if (!value) {
          this.widgetIdToRemove = null
        }
      },
    },

    widgetToRemove() {
      return this.getWidget(this.widgetIdToRemove)
    },

    widgetDeletingModalProps() {
      return this.widgetIdToRemove ? {
        title: this.$t('streamStats.deleteTitle'),
        message: this.$t('streamStats.deleteMessage', { widgetTitle: this.widgetToRemove.title }),
      } : {}
    },
  },
  methods: {
    ...mapActions('streamStats/widget', ['fetchWidgetsList', 'deleteWidget']),

    askToDeleteWidget(widgetId) {
      this.widgetIdToRemove = widgetId
    },

    removeWidget(widgetId) {
      this.loadingState.list = true

      this.deleteWidget(widgetId)
        .finally(() => {
          this.loadingState.list = false
        })
    },

    copyWidget(sourceWidgetId) {
      this.$router.push({
        name: 'dashboard-widget-stream-stats',
        params: { id: 'create' },
        query: { sourceWidgetId },
      })
    },
  },
  created() {
    this.fetchWidgetsList()
      .finally(() => {
        this.loadingState.list = false
      })
  },
}
</script>
