<style scoped lang="scss">
  .widget-stream-stats {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;

    .stats-list {
      width: 100%;
      overflow: hidden;
    }

    .stats-marquee {
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;

      .marquee-wrapper {
        display: flex;
        white-space: nowrap;

        .stats-list-item {

        }

        .stats-list-item + .stats-list-item {
          margin-left: 80px;
        }
      }
    }

    .stats-slider {
      position: relative;

      .stats-slider-wrapper {
        display: flex;
        align-items: center;
      }

      .stats-list-item {
        width: 100%;
      }
    }

    &.v-align-top {
      justify-content: flex-start;

      .marquee-wrapper {
        top: 0;
      }
    }

    &.v-align-middle {
      justify-content: center;
    }

    &.v-align-bottom {
      justify-content: flex-end;

      .marquee-wrapper {
        bottom: 0;
      }
    }
  }

  .fade-in-out {
    &-enter {
      opacity: 0;
    }

    &-leave {
      position: absolute;
    }

    &-leave-to {
      opacity: 0;
      position: absolute;
    }

    &-enter-active, &-leave-active {
      transition: opacity 300ms;
    }
  }
</style>

<template>
  <div class="widget-stream-stats" :class="widgetClasses" ref="widget">
    <template v-if="processedDataType === 'list'">
      <div
        v-if="settings.displayMode === 'slider'"
        class="stats-slider">
        <transition-group tag="div" class="stats-slider-wrapper" name="fade-in-out">
          <div
            v-for="(item, index) in processedDataList"
            v-show="currentSlideIndex === index"
            :key="index"
            class="stats-list-item">
            <div
              v-if="item.mainRow"
              class="stats-item-main-row"
              :style="mainRowTextStyles">{{ item.mainRow }}
            </div>
            <div
              v-if="item.additionalRow"
              class="stats-item-additional-row"
              :style="additionalRowTextStyles">{{ item.additionalRow }}
            </div>
          </div>
        </transition-group>
      </div>
      <div
        v-if="settings.displayMode === 'list'"
        class="stats-list list">
        <div
          v-for="(item, index) in processedDataList"
          :key="index"
          class="stats-list-item">
          <div
            v-if="item.mainRow"
            class="stats-item-main-row"
            :style="mainRowTextStyles">{{ item.mainRow }}
          </div>
        </div>
      </div>
      <div
        v-if="settings.displayMode === 'marquee'"
        class="stats-marquee">
        <div class="marquee-wrapper" ref="marqueeList" :style="marqueeStyles">
          <div
            v-for="(item, index) in processedDataList"
            :key="index"
            class="stats-list-item">
            <div
              v-if="item.mainRow"
              class="stats-item-main-row"
              :style="mainRowTextStyles">{{ item.mainRow }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="processedDataType === 'object'">
      <div
        class="stats-main-row"
        :style="mainRowTextStyles">{{ processedDataObject.mainRow }}
      </div>
    </template>
  </div>
</template>

<script>

import SvgGradient from '@components/BaseComponents/SvgGradient'
import { numbersToUnits } from '@utils/utils'

import { defaultWidgetDesign, defaultWidgetSettings } from '@src/config/streamStats/defaultValues'
import dataTypeSettings from '@src/config/streamStats/dataTypeSettings'

export default {
  name: 'WidgetStreamStats',
  components: { SvgGradient },
  props: {
    widgetDesign: {
      type: Object,
      required: true,
    },
    widgetSettings: {
      type: Object,
      required: true,
    },
    widgetData: {
      default: null,
    },
  },
  data() {
    return {
      design: defaultWidgetDesign(),
      settings: defaultWidgetSettings(),

      currentSlideIndex: 0,
      sliderTimer: null,

      marqueeListOffset: 0,
      marqueeListTargetOffset: 0,
      marqueeTransitionTime: 0,
      marqueeTimer: null,
      marqueeStyles: {},

      processedDataList: [],
    }
  },
  computed: {
    widgetClasses() {
      return {
        [`v-align-${this.design.verticalAlign}`]: true,
      }
    },

    dataTypeSettings() {
      return dataTypeSettings[this.settings.dataType]
    },

    processedDataType() {
      return [
        'lastDonations',
        'topDonations',
        'topDonators',
        'lastSubscribers',
      ].includes(this.settings.dataType) ? 'list' : 'object'
    },

    processedDataObject() {
      if (this.processedDataType !== 'object') {
        return
      }

      return {
        mainRow: this.processTemplateString(this.mainRowTemplate, this.widgetData),
      }
    },

    mainRowTemplate() {
      let mainRowTemplate

      const { template } = this.settings
      const { allowTemplate, templatePlaceholder } = this.dataTypeSettings

      if (allowTemplate) {
        if (template && template.length) {
          mainRowTemplate = template
        } else {
          mainRowTemplate = templatePlaceholder
        }
      }

      return mainRowTemplate
    },

    additionalRowTemplate() {
      let additionalRowTemplate

      const { displayMode, additionalTemplate } = this.settings

      if (displayMode === 'slider') {
        if (additionalTemplate && additionalTemplate.length) {
          additionalRowTemplate = additionalTemplate
        }
      }

      return additionalRowTemplate
    },

    mainRowTextStyles() {
      if (!_.get(this.design, 'mainRowTextStyles')) {
        return null
      }

      const { mainRowTextStyles } = this.design
      const { strokeWidth, strokeColor } = mainRowTextStyles

      return {
        ...numbersToUnits(mainRowTextStyles, 'px'),
        '-webkit-text-stroke': strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
        textStroke: strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
        fontFamily: `"${mainRowTextStyles.fontFamily}"`,
      }
    },

    additionalRowTextStyles() {
      if (!_.get(this.design, 'additionalRowTextStyles')) {
        return null
      }

      const { additionalRowTextStyles } = this.design
      const { strokeWidth, strokeColor } = additionalRowTextStyles

      return {
        ...numbersToUnits(additionalRowTextStyles, 'px'),
        '-webkit-text-stroke': strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
        textStroke: strokeWidth ? `${strokeWidth}px ${strokeColor}` : null,
        fontFamily: `"${additionalRowTextStyles.fontFamily}"`,
      }
    },

    startCurrentModeDebounced() {
      return _.debounce(this.startCurrentMode, 300)
    },

    _modesMethods() {
      return {
        marquee: {
          start: this.startMarqueeRotation,
          stop: this.stopMarqueeRotation,
        },
        slider: {
          start: this.startSliderRotation,
          stop: this.stopSliderRotation,
        },
      }
    },
  },
  methods: {
    processTemplateString(string, data) {
      if (!string || _.isNil(data)) {
        return null
      }

      const tags = this.dataTypeSettings.templateTags

      const rules = {
        username: data.username ?? 'Аноним',
        amount: `${data.amount} ${data.currency}`,
        message: data.message ?? '',
        media: data.media ?? '',
        number: data ?? '0',
      }

      tags.forEach(tag => {
        string = string.replace(`{${tag}}`, rules[tag])
      })

      return string
    },

    processDataList() {
      if (this.processedDataType !== 'list') {
        return
      }

      this.processedDataList = this.widgetData
        .slice(0, this.settings.rowsNumber)
        .map(item => {
          return {
            mainRow: this.processTemplateString(this.mainRowTemplate, item),
            additionalRow: this.processTemplateString(this.additionalRowTemplate, item),
          }
        })
    },

    startSliderRotation() {
      this.stopSliderRotation()

      this.processDataList()

      if (this.processedDataList.length > 1) {
        this.$nextTick(() => {
          const { rowsNumber, widgetSpeed } = this.settings

          const nextSlide = () => {
            const lastSlideIndex = _.min([rowsNumber, this.processedDataList.length]) - 1

            if (this.currentSlideIndex > lastSlideIndex) {
              this.startSliderRotation()
            } else {
              this.sliderTimer = setTimeout(() => {
                this.currentSlideIndex++
                nextSlide()
              }, 12000 - (10500 * widgetSpeed / 100))
            }
          }

          nextSlide()
        })
      }
    },

    stopSliderRotation() {
      this.currentSlideIndex = 0

      clearTimeout(this.sliderTimer)
    },

    prepareMarquee() {
      const widget = this.$refs.widget
      const marquee = this.$refs.marqueeList

      const { widgetSpeed } = this.settings

      if (widget) {
        const { width: widgetWidth } = widget.getBoundingClientRect()
        this.marqueeListOffset = widgetWidth

        if (marquee) {
          const { width: marqueeWidth } = marquee.getBoundingClientRect()
          this.marqueeListTargetOffset = marqueeWidth + widgetWidth

          this.marqueeTransitionTime = this.marqueeListTargetOffset / (100 + (200 * (widgetSpeed / 100)))

          this.marqueeStyles = {
            marginLeft: `${this.marqueeListOffset}px`,
            transform: `translateX(-${this.marqueeListTargetOffset}px)`,
            transition: `transform ${this.marqueeTransitionTime}s linear`,
          }
        }
      }
    },

    startMarqueeRotation() {
      this.stopMarqueeRotation()
      this.processDataList()

      this.$nextTick(() => {
        this.prepareMarquee()

        this.marqueeTimer = setTimeout(() => {
          this.startMarqueeRotation()
        }, this.marqueeTransitionTime * 1000)
      })
    },

    stopMarqueeRotation() {
      clearTimeout(this.marqueeTimer)

      this.marqueeStyles = {
        marginLeft: `${this.marqueeListOffset}px`,
        transition: 'transform 0s linear',
      }
    },

    startCurrentMode() {
      if (this._modesMethods[this.settings.displayMode]) {
        this._modesMethods[this.settings.displayMode].start()
      }
    },
    stopMode(mode) {
      if (this._modesMethods[mode]) {
        this._modesMethods[mode].stop()
      }
    },
    stopCurrentMode() {
      this.stopMode(this.settings.displayMode)
    },

    reactOnChanges() {
      if (this.dataTypeSettings.allowDisplayModes) {
        if (this.settings.displayMode === 'list') {
          this.processDataList()
        } else {
          this.startCurrentModeDebounced()
        }
      }
    },
  },
  beforeDestroy() {
    this.stopCurrentMode()
  },
  watch: {
    'settings.displayMode'(value, oldValue) {
      this.stopMode(oldValue)
      this.startCurrentMode()
    },
    'settings.widgetSpeed'() {
      this.startCurrentModeDebounced()
    },
    'settings.rowsNumber'() {
      this.startCurrentModeDebounced()

      if (this.settings.displayMode === 'list') {
        this.processDataList()
      }
    },
    'settings.dataType': 'reactOnChanges',
    'settings.template': 'reactOnChanges',
    'settings.additionalTemplate': 'reactOnChanges',

    widgetData: {
      handler() {
        this.$nextTick(() => {
          if (this.settings.displayMode === 'list') {
            this.processDataList()
          }
        })
      },
      immediate: true,
    },

    widgetSettings: {
      handler(value) {
        this.settings = value
      },
      immediate: true,
    },

    widgetDesign: {
      handler(value) {
        this.design = value
      },
      immediate: true,
    },

    // $appWidth: 'setMarqueeListOffset',
  },
}
</script>
